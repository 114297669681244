import Hero from "../components/Hero";
import Description from "../components/Description";
import LeftPhoto from "../components/LeftPhoto";
import RightPhoto from "../components/RightPhoto";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <>
      <Hero />
      <RightPhoto />
      <LeftPhoto />
      <Description />
      <Footer />
    </>
  );
};

export default Home;
