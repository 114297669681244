import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Config from "../config/Details";

const Privacy = () => {
  return (
    <>
      <Navbar />
      <section className="text-left p-4 flow-content  max-w-6xl m-auto">
        <h3 className="text-3xl font-bold">Privacy</h3>
        <h4 className="text-2xl">Introduction</h4>
        <p>
          We understand your concerns about privacy and we respect your privacy.
          This is why we have taken the time to disclose our information
          collection practices and our privacy policy. We want you to understand
          what types of information we collect and what might happen to that
          information if you use any of the products, applications, or
          extensions (the “Services”) from the {Config.company}. If you have
          questions or concerns regarding this Privacy Policy, you may contact
          us at {Config.email}. Alternatively, you may contact us at:
          {Config.address}
        </p>
        <h4 className="text-2xl">What Information We Collect</h4>
        <p>
          We are the sole owners of the information collected on this site. We
          will collect information that you voluntarily give us through the
          registration process, email, or other direct contacts from you. This
          information can include name, address, phone, email, and any other
          information you provide us. If we offer a paid subscription service
          and you choose to subscribe, you may be asked to provide credit card
          or other payment information. If you are using the Services on your
          mobile device, you can also choose to provide us with location data.
          If you choose to share any information, materials, or other content
          with another person, we will ask you to provide a valid email address
          for that person.
        </p>
        <p>
          You may also give us permission to access your information in other
          services. For example, you may give us permission to get feeds from
          your Twitter account, which allows us to obtain information from that
          account (e.g. your name and personal photo to pre-populate into your
          user profile). The information we obtain from those services often
          depends on your settings or their privacy policies, so please be sure
          to check what those are. However, any information thus collected is
          not stored anywhere else.
        </p>
        <p>
          We also collect information if you contact us for support or help,
          submit an application, or otherwise communicate with us. The
          information which you supply to us enables us to customize and enhance
          the services we provide to you. In addition, it helps us communicate
          with you about our news, events, promotions, and new products.
        </p>
        <p>
          In addition to the information you voluntarily provide us, we collect
          technical information when you use our services, or use websites or
          applications from the {Config.company}. Whenever you use a website,
          mobile application, or other Internet services, there is certain
          information that gets created and recorded automatically. As an
          example, we might collect information about the URLs, listing titles
          and content from the web pages, and other information you might save
          to {Config.product}. There are other types of information we might
          collect, such as (1) Log data, where our servers will automatically
          record information your browser sends whenever you visit a website or
          your mobile app sends when you’re using it; (2) Cookie data, where we
          use a "cookie" or other technologies to record settings and
          preferences so you don’t have to set them up every time you visit; and
          (3) Device information, where we may collect information about the
          device you are using {Config.product} on, including the type of device
          it is, the operating system you're using, device settings, unique
          device identifiers, and crash data. We compile aggregated data by
          combining information from many users, without including any
          information that could be used to identify you or anyone else by name.
        </p>
        <h4 className="text-2xl">Collection of Information From Children</h4>
        <p>
          We comply with the Children's Online Privacy Protection Act.{" "}
          {Config.product} is not directed to children under 13 and if you learn
          that your minor child has provided us with personal information
          without your consent, please contact us. We do not knowingly collect
          any personal information from children under 13 and if we learn that
          we have inadvertently done so, we will promptly delete it.
        </p>
        <h4 className="text-2xl">How We Use the Information We Collect</h4>
        <p>
          We use the information we collect to enable the use of our service,
          such as saving information, materials, or other content to{" "}
          {Config.product} and making such content available to you at a later
          time on any device that you use to access the Services. In addition,
          if you choose to use {Config.product} to share content with another
          person, we will use the recipient’s email address or user account
          information to provide that service.
        </p>
        <p>
          We also use the information we collect to make the Service better,
          develop new products, and protect {Config.product} and our users. We use
          the information we collect to tailor the site for its visitors,
          including offering you more customized content and showing ads you
          might be interested in. We may also use your contact information to
          respond to your inquiries or regarding any other reason you contact
          us.
        </p>
        <p>
          We will also use the information we collect to send you notifications
          about updates (such as when certain activity, like comments, happens
          on {Config.page}), newsletters, marketing materials, and other
          information that may be of interest to you.
        </p>
        <p>
          We may also aggregate non-identifying information to analyze the
          manner in which the Services are used. Such information includes the
          manner in which information, materials, or other content has been
          accessed, saved, and shared. We may use the aggregated information to
          offer a list of top sites or content, make suggestions to our users,
          or report on usage and trends. We may also analyze and use the
          aggregated information to improve the products and services that we
          offer and to develop new products and services. We may also aggregate
          non-identifying information to provide website owners with statistics
          on bookmarking activity.
        </p>
        <h4 className="text-2xl">Control Over the Information You Provide </h4>
        <p>
          The {Config.product} application allows you to update or change the
          information you provided to us during registration. You may do so by
          logging in and choosing the "Settings" options. If you have any
          questions about updating the information you have provided please
          contact us at {Config.email}. If you have signed up to receive emails
          from us and would like to opt-out of any future contact you can do so
          at any time by giving us a call or sending us an email to the contact
          information provided on our website. You can also do the following by
          contacting us:
        </p>
        <ul className="ml-12 list-disc flow-content">
          <li>See what data we have about you if any</li>
          <li>Change/correct any data we have about you</li>
          <li>Have us delete any data we have about you</li>
          <li>Express any concern you have about our use of your data</li>
        </ul>
        <h4 className="text-2xl">Security to Protect Sensitive Information</h4>
        <p>
          We take precautions to protect against the loss, misuse, and/or
          unauthorized alteration of your information under our control. When
          you submit sensitive information via the site, your personally
          identifiable information is protected with both online and offline
          security methods, including firewalls, passwords, and restricted
          physical access to the place where your information is stored.
        </p>
        <p>
          Wherever we collect sensitive information (such as credit card data),
          that information is encrypted and transmitted to us in a secure way.
          You can verify this by looking for a closed lock icon at the bottom of
          your web browser, or looking for the "HTTPS" at the beginning of the
          address of the web page.
        </p>
        <p>
          While we use encryption to protect sensitive information transmitted
          online, we also protect your information offline. Only employees who
          need the information to perform a specific job (for example, billing
          or customer service) are granted access to personally identifiable
          information, if necessary. The computers/servers in which we store
          personally identifiable information are kept in a secure environment.
        </p>
        <p>
          It is possible, however, that a third party will circumvent such
          security measures, and we do not guarantee that our security measures
          will successfully prevent third parties from accessing the information
          we collect.
        </p>
        <p>
          If you believe that our site has collected incorrect information or if
          you would like to dispute any information, please contact us using the
          address at the top of this page.
        </p>
        <h4 className="text-2xl">Sharing of Information We Collect</h4>
        <p>
          Our service also makes use of Microsoft Bing to provide search functionalities in our products.<br/>
          If you choose to make the content you save publicly available, we may
          share that information with others in accordance with the options you
          select. In addition, if you choose to use the Services to share
          information, materials, or other content with another person, we will
          send an email on your behalf to that person with a link to the content
          that you have chosen to share. We may also share aggregated data and
          related usage information with third parties, but this aggregated
          information does not contain any personal information which can
          identify you or any other individual user. We do not disclose or use
          the information we share with them.
        </p>
        <p>
          We do not share any personally identifiable information with any third
          party, nor do we share any other private or personal information
          unless you expressly agree that we may do so. We may employ
          third-party companies or individuals to process personal information
          to facilitate or outsource one or more aspects of the products and
          services that we provide you. These third-party companies are subject
          to confidentiality agreements with us and other legal restrictions
          that prohibit their use of the information we provide to them for any
          other purpose except to facilitate the specific outsourced operation.
        </p>
        <h4 className="text-2xl">Modifying This Policy</h4>
        <p>
          Please note this Privacy Policy will change from time to time. We
          might modify this Privacy Policy at any time by posting the modified
          terms on our Website which you can access at {Config.website}. If you
          continue to use {Config.product} after those changes are in effect, you
          agree to the revised policy. If the changes are significant or affect
          user information already stored in our database, we may provide more
          prominent notice or obtain your consent as required by law. Our use of
          your information shall be governed by the terms and conditions of our
          Privacy Policy in effect when you first use our application.
        </p>
        <p>
          If you have questions about any of the provisions described above,
          please contact us at {Config.email} or at the street address listed
          above.
        </p>
      </section>
      <Footer />
    </>
  );
};

export default Privacy;
