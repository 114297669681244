import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Config from "../config/Details";

const Terms = () => {
  return (
    <>
      <Navbar />
      <section className="text-left p-4 flow-content  max-w-6xl m-auto">
        <h3 className="text-3xl font-bold">Terms of Service</h3>
        <h4 className="text-2xl">Introduction</h4>
        <p>
          Please read the following terms of service ("Agreement") before you
          sign-up for the {Config.product} services. The Agreement constitutes a
          binding legal agreement, which governs your use of the Services via
          any platform or device. By installing the application, visiting our
          website, or installing or using any of the Services, you are accepting
          these terms of service. If you do not agree to these terms, please do
          not install our application, access our website, or use any of our
          products or services.
        </p>
        <h4 className="text-2xl">Definitions</h4>
        <p>
          As used in this Agreement the "Services" means all products or
          services we provide through our application, software, or website. The
          Services is owned, licensed, and operated by {Config.company} (referred
          to herein as "we", "us", "our", or "Company"). "Services" shall mean
          our bookmarking service, accessible via {Config.email} or another
          designated website or IP address, or ancillary services rendered to
          you by {Config.company}, to which you are being granted access under this
          Agreement, including application and the content. The "Content" is
          audio and visual information, documents, software, products, and
          services contained or made available to you by us in the course of
          using the Service as mentioned in this Agreement. Any data,
          information, or material provided or submitted by you to the Service
          in the course of using the Service is referred to herein as "Customer
          Content". In this Agreement, “Intellectual Property Rights” means
          unpatented inventions, patent applications, patents, design rights,
          copyrights, trademarks, service marks, trade names, domain name
          rights, know-how, and other trade secret rights, and all other
          intellectual property rights, derivatives thereof, and forms of
          protection of a similar nature anywhere in the world.
        </p>
        <h4 className="text-2xl">The Application and Services</h4>
        <p>
          The visual and audio data, listing information, and other content that
          you choose to save may be protected by copyright and other laws.
          Please refer to the website you were visiting when you saved such
          content for copyright notices, information, and restrictions that may
          be applicable to such content. We respect the intellectual property of
          others, and we ask our users to do the same. The application and
          Services allow you to store and organize across devices and platforms
          so that you can access the information later that you had the right to
          access when you saved such content to us. As a condition to your use
          of the Services, you agree to abide by our Copyright Policy, which is
          set out in full below, and you further agree not to use the
          application and Services to infringe any intellectual property rights
          or to circumvent the terms and conditions of any third party’s website
          or content controls. We reserve the right, at any time, in our sole
          discretion with or without notice, to remove or disable access to
          content, terminate the accounts of, and block access to the
          application and Services to, any users who infringe any copyrights or
          other intellectual property rights or about whom we receive complaints
          of infringement or other illegal, offensive, or harassing conduct.
        </p>
        <h4 className="text-2xl">License Grant and Restrictions</h4>
        <p>
          You may use the Services only if you can form a binding contract with{" "}
          {Config.company}, and only in compliance with this Agreement and all
          applicable laws. Any use of access by anyone under the age of 13 is
          prohibited. If you open an account on behalf of a company,
          organization, or other entity, then (a) "you" includes you and that
          entity, and (b) you represent and warrant that you are authorized to
          grant all permissions and licenses provided in these Terms and bind
          the entity to these Terms and that you agree to these Terms on the
          entity's behalf. Some of our Products may be software that is
          downloaded to your computer, phone, tablet, or other devices. You
          agree that we may automatically upgrade those Products, and these
          Terms will apply to such upgrades. We hereby grant you a
          non-exclusive, non-transferable, revocable right to access and use the
          application and Services, solely with supported browsers through the
          Internet for your own internal non-commercial purposes, subject to the
          terms and conditions of this Agreement. You may not permit the
          application and the Services to be used by or for the benefit of
          unauthorized third parties. Nothing in this Agreement shall be
          construed to grant you any right to transfer or assign rights to
          access or use the application and Services. All rights not expressly
          granted to you are reserved by the Company and its licensors. If you
          want to make commercial use of any of the applications and Services,
          you must enter into a separate written agreement with us in advance.
          The license provided herein is effective until terminated. This
          license automatically terminates if you fail to comply with the terms
          and conditions of this Agreement. You agree that, upon the termination
          of the License for any reason, you will destroy or permanently erase
          all copies of the applications and Services. We reserve the right to
          refuse service, terminate accounts, or limit access to the application
          and Services at our sole discretion.
        </p>
        <h4 className="text-2xl">Intellectual Property Ownership</h4>
        <p>
          {Config.company} alone (and its licensors, where applicable) shall own
          all rights, title, and interest, including all related Intellectual
          Property Rights, in and to the application, the Content, and the
          Services, and any suggestions, enhancement requests, feedback,
          recommendations or other information provided by you or any other
          party relating to the Service. This Agreement is not a sale and does
          not convey to you any rights of ownership in or related to the
          Services, the application, or the Intellectual Property Rights owned
          by {Config.company}. The name, the logo, and the product names associated
          with the Services are trademarks of {Config.company} or third parties,
          and no right or license is granted to use, re-use, or re-purpose them.
          Except as otherwise provided in this Agreement, you retain all rights,
          titles, and interests to User Content.
        </p>
        <h4 className="text-2xl">Privacy</h4>
        <p>
          {Config.company}'s privacy policies, which are incorporated herein by
          reference, may be viewed at Privacy Policy.
        </p>
        <h4 className="text-2xl">Security</h4>
        <p>
          We care about the security of our users. While we work to protect the
          security of your content and account, {Config.company}, cannot guarantee
          that unauthorized third parties will not be able to defeat our
          security measures. Please notify us immediately of any compromise or
          unauthorized use of your account.
        </p>
        <h4 className="text-2xl">Third-Party Links, Sites and Services</h4>
        <p>
          The content, products, and services available via the application and
          Services include materials from non-affiliated third parties. We also
          participate in joint marketing and other service offerings with third
          parties and our website contains links to third-party sites. Such
          third parties may provide you with an opportunity to install the
          application or use the Services from or through their websites or such
          third parties may request to access or use your user account or other
          information so that they can customize the services that they provide
          to you. For example, if you are on a third-party website, you may be
          provided with the opportunity to download and install the application
          or you may be redirected to our website or provided with access to the
          Services. The third parties that contract with us are independent and
          are not our agents. Such links are provided for your convenience only.
          We do not control and are not responsible for, the content of, or
          products or services available through, any third-party websites,
          including without limitation the websites that offer videos, articles,
          or other content that you choose to save to our Services. We are not
          responsible for examining or evaluating any third-party content. You
          understand and agree that we do not warrant and shall not be liable
          for any third-party materials or websites. You also agree that you
          will not use any third-party content or materials in a manner that
          would infringe or violate the rights of any other party and that we
          are not in any way responsible for any such use by you. If you access
          any third-party website, service, or content from Us, you do so at
          your own risk and you agree that the Company will have no liability
          arising from your use of or access to any third-party website,
          service, or content.
        </p>
        <p>
          Our service also makes use of Microsoft Bing to provide search
          functionalities in our products.
        </p>
        <h4 className="text-2xl">Termination</h4>
        <p>
          Any breach of the terms of this Agreement or unauthorized use of the
          Services will be deemed a material breach of this Agreement.{" "}
          {Config.company}, in its sole discretion, may immediately terminate your
          password, account, or use of the Service or application if you breach
          or otherwise fail to comply with this Agreement. You agree and
          acknowledge that {Config.company} has no obligation to refund fees for
          service or retain the User Content, and may delete such User Content
          if you have materially breached this Agreement. Upon any termination
          of this Agreement, you must promptly destroy all materials downloaded
          or otherwise obtained from us, as well as all copies of such
          materials. Termination of this Agreement shall not act as a waiver of
          any breach of this Agreement and shall not release you from any
          liability for breach of your obligations under this Agreement.
        </p>
        <h4 className="text-2xl">Representation and Warranties</h4>
        <p>
          Each party represents and warrants that it has the legal power and
          authority to enter into this Agreement. The User further represents
          and warrants that its use of the Services and {Config.product} will be
          on a non-commercial basis and that the user will not make use of the
          Services or the {Config.product} in any fashion that infringes on
          another person’s intellectual property rights, breaches the terms of
          any other agreement binding the user, or otherwise breaks any
          applicable law.
        </p>
        <h4 className="text-2xl">Disclaimer of Warranties</h4>
        <p>
          {Config.company} takes no responsibility and assumes no liability for any
          User Content that you or any other user or third party posts or
          transmits using our Service.
        </p>
        <h4 className="text-2xl">Limitation of Liability</h4>
        <p>
          There are inherent risks in relying upon, using, or retrieving any
          information found on the Internet, and we urge you to make sure you
          understand these risks before using the {Config.product} or any website
          that provides you with access to the {Config.product}.
        </p>
        <p>
          IN NO EVENT SHALL {Config.company}'S AGGREGATE LIABILITY EXCEED THE
          AMOUNT ACTUALLY PAID BY YOU IN THE TWELVE (12) MONTH PERIOD
          IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO SUCH CLAIM. IN NO EVENT
          SHALL {Config.company} AND/OR ITS LICENSORS BE LIABLE TO ANYONE FOR ANY
          INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL, OR
          OTHER DAMAGES OF ANY TYPE OR KIND (INCLUDING LOSS OF DATA, REVENUE,
          PROFITS, USE, OR OTHER ECONOMIC ADVANTAGE) ARISING OUT OF, OR IN ANY
          WAY CONNECTED WITH THIS SERVICE, INCLUDING BUT NOT LIMITED TO THE USE
          OR INABILITY TO USE THE SERVICE, OR FOR ANY CONTENT OBTAINED FROM OR
          THROUGH THE SERVICE, ANY INTERRUPTION, INACCURACY, ERROR OR OMISSION,
          REGARDLESS OF CAUSE IN THE CONTENT, EVEN IF {Config.company} OR SUCH ITS
          LICENSORS HAVE BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES.
        </p>
        <p>
          {Config.company} and its licensors make no representation that the
          Service is appropriate or available for use in other locations. If you
          use the Service from outside the United States of America and/or the
          European Union, you are solely responsible for compliance with all
          applicable laws, including without limitation export and import
          regulations of other countries. Any diversion of the Content contrary
          to the United States or European Union (including European Union
          Member States) law is prohibited.
        </p>
        <h4 className="text-2xl">Indemnification</h4>
        <p>
          You agree to honor copyright laws and all other applicable laws in
          your use of this service.
        </p>
        <p>
          YOU AGREE TO DEFEND, INDEMNIFY AND HOLD HARMLESS, TO THE DEGREE
          PERMITTED BY APPLICABLE LAW, {Config.website}, ITS PARENT CORPORATION (
          {Config.company}), AND ALL OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS OF
          {Config.website} OR {Config.company}, FROM ANY AND ALL CLAIMS, DAMAGES,
          OBLIGATIONS, DEBT, LOSSES, LIABILITIES, COSTS, AND EXPENSES (INCLUDING
          ATTORNEY’S FEES) FROM: (I) YOUR USE OF TO THE SERVICE; (II) YOUR
          INFRINGEMENT OF THESE TERMS OF SERVICE; (III) YOUR INFRINGEMENT OF ANY
          THIRD PARTY RIGHT, INCLUDING WITHOUT LIMITATION COPYRIGHT
          INFRINGEMENT, TRADEMARK INFRINGEMENT, VIOLATION OF PRIVACY RIGHTS OR
          PUBLICITY RIGHTS, AND ANY OTHER CAUSE OF ACTION WHETHER ARISING IN
          TORT OR CONTRACT, AT LAW OR IN EQUITY; OR (IV) ANY CLAIM THAT YOUR
          CONTENT CAUSED DAMAGE TO ANY PARTY UNDER ANY LEGAL OR EQUITABLE THEORY
          WHATSOEVER. THIS DEFENSE AND INDEMNIFICATION OBLIGATION WILL SURVIVE
          THESE TERMS OF SERVICE AND YOUR USE OF THE SERVICE. YOU AGREE, AT YOUR
          OWN EXPENSE, TO INDEMNIFY, DEFEND AND HOLD HARMLESS {Config.company},
          ITS PARENTS, SUBSIDIARIES AND AFFILIATES, AND THEIR OFFICERS,
          DIRECTORS, EMPLOYEES, AGENTS, DISTRIBUTORS, AND LICENSEES, FROM AND
          AGAINST ANY JUDGEMENTS, LOSSES, DEFICIENCIES, DAMAGES, LIABILITIES,
          COSTS, CLAIMS, DEMANDS, SUITS, AND EXPENSES (INCLUDING, WITHOUT
          LIMITATION, REASONABLE ATTORNEYS’ FEES, EXPERT WITNESS FEES, AND
          EXPENSES) INCURRED IN, ARISING OUT OF OR IN ANY WAY RELATED TO YOUR
          BREACH OF THIS AGREEMENT, YOUR USE OF THE SERVICES, OR ANY OF YOUR
          OTHER ACTS OR OMISSIONS.
        </p>
        <h4 className="text-2xl">No Assignment</h4>
        <p>
          This Agreement may not be assigned, in whole or part, whether by
          operation of law or otherwise, including without limitation in
          connection with a merger, acquisition, or sale of assets, by you
          without the prior written approval of {Config.company}. Any purported
          assignment in violation of this section shall be void.
        </p>
        <h4 className="text-2xl">Governing Law and Jurisdiction</h4>
        <p>
          This Agreement shall be governed by the law of USA, without
          regard to the choice or conflicts of law provisions of any
          jurisdiction, and any disputes, actions, claims, or causes of action
          arising out of or in connection with this Agreement or the Service
          shall be subject to the exclusive jurisdiction of the state and
          federal courts located in Delaware, USA. If any provision of
          this Agreement is held by a court of competent jurisdiction to be
          invalid or unenforceable, then such provision(s) shall be construed,
          as nearly as possible, to reflect the intentions of the invalid or
          unenforceable provision(s), with all other provisions remaining in
          full force and effect. No joint venture, partnership, employment, or
          agency relationship exists between you and {Config.company} as a result
          of this agreement or use of the Service. The failure of {Config.company}
          to enforce any right or provision in this Agreement shall not
          constitute a waiver of such right or provision unless acknowledged and
          agreed to by {Config.company} in writing.
        </p>
        <h4 className="text-2xl">Modification to Terms</h4>
        <p>
          {Config.company} reserves the right to modify the terms and conditions of
          this Agreement or its policies relating to the Service at any time,
          and without notice, effective upon posting of an updated version of
          this Agreement on the Service. You are responsible for regularly
          reviewing this Agreement. Continued use of the Service after any such
          changes shall constitute your consent to such changes.
        </p>
        <h4 className="text-2xl">Entire Agreement</h4>
        <p>
          This Agreement comprises the entire agreement between you and
          {Config.company} with respect to your use of the Service and supersedes
          all prior or contemporaneous negotiations, discussions, or agreements,
          whether written or oral, between the parties regarding the subject
          matter contained herein; provided however that users of the
          Professional and Personal Editions of the Service may be subject to
          additional terms and conditions. In the event of any conflict between
          this Agreement and such additional terms and conditions, the
          additional terms and conditions shall govern.
        </p>
      </section>
      <Footer />
    </>
  );
};

export default Terms;
