import React from "react";
import PreviewOne from "../image/previewOne.png";

const RightPhoto = () => {
  return (
    <section className="w-full bg-white pt-24 pb-24">
      <div className="max-w-7xl m-auto pl-6 pr-6 grid sm:grid-cols-1 md:grid-cols-2">
        <div className="col-span-1 text-left pl-10 pr-10 flex justify-center items-center">
          <div>
            <h2 className=" text-4xl font-semibold">A better new tab</h2>
            <p className="text-gray-700 pt-4 pb-4 tracking-wide">
              Remove the clutter and focus on what matters with our kryopage
              extension. Using the search functionality, powered by Microsoft
              Bing quickly search through internet from the new tab of your
              browser.
            </p>
          </div>
        </div>
        <div className="col-span-1 flex justify-center items-center">
          <img
            className="shadow-lg"
            src={PreviewOne}
            width="500px"
            alt="Preview of the kryopage extension"
          />
        </div>
      </div>
    </section>
  );
};

export default RightPhoto;
