import React from "react";

import { motion } from "framer-motion";
import PreviewTwo from "../image/previewTwo.png";

const LeftPhoto = () => {
  return (
    <section className="w-full bg-secondary pt-24 pb-24">
      <div className="max-w-7xl m-auto pl-6 pr-6 grid sm:gap-2 sm:grid-cols-1 md:grid-cols-2">
        <div className="col-span-1 flex justify-center items-center">
          <motion.img
            className="shadow-lg"
            src={PreviewTwo}
            width="500px"
            alt="left image"
            initial={{ opacity: 0, x: 70 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          />
        </div>
        <div className="col-span-1 text-left pl-10 pr-10 flex justify-center items-center">
          <div>
            <h2 className=" text-4xl font-semibold">Widgets</h2>
            <p className="text-gray-700 pt-4 pb-4 tracking-wide">
              Kryopage provides a lot of different widgets such as the
              translation widget, different timezone widget and more.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LeftPhoto;
