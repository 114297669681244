import React from "react";
import p from "../config/prod";

import { motion } from "framer-motion";

const Content = () => {
  const redirectToPlayStore = () => {
    p.Click();
  };

  return (
    <div className=" grid sm:grid-cols-1  md:grid-cols-2 max-w-6xl m-auto pl-8 pr-8 pt-36 pb-32 -mt-4 font-Montserrat ">
      <div className=" col-span-1 flex items-center">
        <div className="sm:text-center md:text-left -mt-10 md:pr-8">
          <motion.h1
            className="text-3xl font-semibold block w-full col-span-full mb-10 md:pr-12 tracking-wide"
            initial={{ opacity: 0, x: 70 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            Power up the new tab with apps to get the personalized experience
          </motion.h1>
          <motion.button
            className="font-Montserrat uppercase tracking-wide rounded bg-primary  text-lg hover:bg-transparent hover:text-primary hover:border-primary border-2 border-primary  font-medium text-white  py-4 px-8  transition duration-300 ease-out"
            initial={{ opacity: 0, x: -70 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 0.6 }}
            onClick={redirectToPlayStore}
          >
            Get Extension
          </motion.button>
        <small style={{display:"block", marginTop:"15px", fontWeight:"bold"}}>*Our service also makes use of Microsoft Bing to provide search functionalities in our products.</small>
        </div>
      </div>
      <motion.div
        className=" mt-2 col-span-1 flex justify-center text-left items-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5, delay: 1.8 }}
      >
        <img
          src="https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE4LqQX?ver=fe80"
          alt=""
        />
      </motion.div>
    </div>
  );
};

export default Content;

// https://adforia.com/wp-content/uploads/elementor/thumbs/home-hero-section-man-oviq0dll9wzwhdvq60jbg67fdm47b4z89394m1l81y.png
