import React from "react";
import Navbar from "../components/Navbar";
import { useLocation } from "react-router-dom";
import p from "../config/prod";
import "./uninstall.css";

const Uninstall = () => {
  const location = useLocation();
  React.useEffect(() => {
    if (location.pathname === "/uninstall") {
      p.Uninstall();
    }
  }, [location]);

  return (
    <div>
      <Navbar />
      <main className="mt-10 flex flex-col  items-center">
        <div className="w-full flex flex-col items-center">
          <div className="goodbye font-medium font-Montserrat ">
            <h1>We are sad to see you leave</h1>
          </div>
          <div className="goodbye font-medium font-Montserrat ">
            <h1>We hope to see you again soon!</h1>
          </div>
        </div>
        <div className="mt-4">
          <img src="/undraw_trip.svg" alt="people leaving" />
        </div>
      </main>
      <footer></footer>
    </div>
  );
};

export default Uninstall;
