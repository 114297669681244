import React from "react";
import { useLocation } from "react-router-dom";
import p from "../config/prod";


const Download = () => {
  const location = useLocation();
  React.useEffect(() => {
      if (location.pathname === "/download") {
          p.InstantClick();
      }
    }, [location]);
  
  return (<div></div>)
};

export default Download;
