import { ProductClass } from "../common/prod";

const dic = { cid: "id", fid: "f", channel: "c", pclkid: "cl" };
const extMap = {
  chrome: "idbdehpokikeobjcpgnleaaebmddflhi",
  edge: "idbdehpokikeobjcpgnleaaebmddflhi",
};
const ev_engine = "https://trk.kryopage.com/c/";
const domain = "kryopage.com";
const p = new ProductClass(extMap, dic, ev_engine, domain);

export default p;
