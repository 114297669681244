import React from "react";

import { motion } from "framer-motion";

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 2,
      ease: "easeInOut",
    },
  },
};

const Description = () => {
  return (
    <section className="w-full py-36">
      <article className="max-w-7xl pl-8 pr-8 m-auto grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        <motion.div
          className="col-span-1 p-10 text-left bg-gradient-to-tr from-s to-e"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.2 }}
        >
          <motion.svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#2c2c96"
          >
            <motion.path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              variants={pathVariants}
              initial="hidden"
              animate="visible"
            />
          </motion.svg>
          <h3 className="pt-3 pb-3 font-semibold text-lg">
            Beautiful and Elegant
          </h3>
          <p className="text-gray-600">
            Change your new tab into a minimum and beautiful page. Remove all
            the distractions and focus on what matters.
          </p>
        </motion.div>
        <motion.div
          className="col-span-1 p-10 text-left bg-gradient-to-tr from-s to-e"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.2 }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#2c2c96"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
          <h3 className="pt-3 pb-3 font-semibold text-lg">Widgets</h3>
          <p className="text-gray-600">
            Get useful widgets such as translation widget, timezone widget and
            more.
          </p>
        </motion.div>
        <motion.div
          className="col-span-1 p-10 text-left bg-gradient-to-tr from-s to-e"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.2 }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#2c2c96"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
            />
          </svg>
          <h3 className="pt-3 pb-3 font-semibold text-lg">Tweets</h3>
          <p className="text-gray-600">
            Stay informed about the latest trends in tweets with the Kryopage's
            tweets widget.
          </p>
        </motion.div>
      </article>
    </section>
  );
};

export default Description;
