import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
const NotFound = () => (
  <>
    <Navbar />
    <section className="text-left p-4 flow-content  max-w-6xl m-auto">
      <h1 className="text-3xl font-bold text-center">
        404 - The page you are looking for does not exist
      </h1>
      {/* Go Back Button  */}
      <div className="flex justify-center mt-4">
        <Link
          to="/"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Go Back
        </Link>
      </div>
    </section>
    <Footer />
  </>
);

export default NotFound;
