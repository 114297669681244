import React from "react";
import Navbar from "./Navbar";
import Content from "./Content";

const Hero = () => {
  return (
      <div className=" h-screen bg-gradient-to-tr from-blue-400 to-green-500">
        <Navbar/>
        <Content />
      </div>
  );
};

export default Hero;


// <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#eff6ff" fill-opacity="1" d="M0,160L360,64L720,224L1080,128L1440,192L1440,0L1080,0L720,0L360,0L0,0Z"></path></svg>
