import React from "react";

import logo from "../image/kryopage.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="w-full font-Montserrat">
      <div className="flex flex-col md:flex-row sm:flex-col   md:justify-between  m-auto pl-5 pr-5 pt-7 pb-7 max-w-6xl font-medium">
        <div className="flex items-center">
          <Link to="/">
            <img src={logo} alt="kryopage logo" width="60" />
          </Link>
          <h1 className="pl-4 font-semibold text-3xl tracking-wide text-blue-900">
            KRYOPAGE
          </h1>
        </div>
        <nav className="flex justify-center items-center">
          <ul className="flex uppercase">
            <li className="pl-4 pr-4">
              <Link
                to="/terms"
                className="hover:text-primary transition ease-out duration-300"
              >
                Terms and Conditions
              </Link>
            </li>
            <li className="pl-4 pr-4">
              <Link
                to="/privacy"
                className="hover:text-primary transition ease-out duration-300"
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
