import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="w-full py-14 font-Montserrat">
      <div className="sm:flex-col md:flex-row gap-4 max-w-5xl px-5 flex  md:justify-between m-auto  text-gray-900">
        <p className="font-medium sm:text-left md:text-center">
          © Moblead LLC 2022 All Rights Reserved
        </p>
        <p className="flex  gap-5">
          <Link to="/terms">Terms and Conditions</Link>
          <Link to="/privacy">Privacy Policy</Link>
        </p>
      </div>
    </div>
  );
};

export default Footer;

// <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#eff6ff" fill-opacity="1" d="M0,32L360,192L720,96L1080,256L1440,96L1440,320L1080,320L720,320L360,320L0,320Z"></path></svg>
